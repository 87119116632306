<template>
  <div class="home">
    <h1>锦享花</h1>
    <!-- <h1>成都融信科技</h1> -->
    <div class="logo">
      <img src="../assets/2.png" align="middle" width="400" hspace="5" vspace="5">
      <!-- <img src="../assets/1.jpeg" align="middle" width="400" hspace="5" vspace="5"> -->
      <!-- <h1>公司简介</h1>
      <p>
        成都融信信息服务有限公司于2020年09月24日成立，注册资金1000万，法定代表人岳星风。
      </p>
      <p>公司经营范围包括：信息技术咨询服务；商务信息咨询；商务服务业；商务代理代办服务；互联网信息服务；法律咨询（不含律师及相关服务）；企业管理咨询；会议、展览及相关服务；软件开发；技术推广服务；销售：电子产品等。</p>
      <p>公司地点：成都市青羊区大墙西街33号1幢13层13号
        <p />
        <a href="https://beian.miit.gov.cn" target="_blank" rel="noopener">
          <p>蜀ICP备2020030967号-1</p>
        </a> -->
    </div>
    <h1>简介</h1>
    <p>
      锦享花是成都融信信息服务有限公司旗下的商标品牌。
    </p>
    <p>服务范围有：广告; 计算机网络上的在线广告; 商业信息代理; 商业中介服务; 商业企业迁移的管理服务; 市场营销; 人事管理咨询; 计算机数据库信息系统化; 财务审计; 医疗用品零售或批发服务。</p>
    <p>公司地点：成都市青羊区大墙西街33号1幢13层13号</p>
    <a href="https://beian.miit.gov.cn" target="_blank" rel="noopener">
      <p>蜀ICP备2020030967号-3</p>
    </a>
  </div>
</template>

<script>
export default {
  name: 'HelloWorld',
  props: {
    msg: String
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.home {
  padding: 8px;
}
.logo {
  width: 800px;
  display: inline-block;
}
img {
  /* width: 400px;
  height: auto; */
}
h3 {
  margin: 40px 0 0;
}
ul {
  list-style-type: none;
  padding: 0;
}
li {
  display: inline-block;
  margin: 0 10px;
}
a {
  color: black;
}
</style>
